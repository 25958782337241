import React, { Component } from "react"
import anime from "animejs"
import Swipe from "react-easy-swipe"

import Intro from "./components/Intro"
import Work, { Slides } from "./components/Work"
import Clients from "./components/Clients"
import Services from "./components/Services"
import Crew from "./components/Crew"

import Reveal from "react-reveal/Reveal"

import smoothscroll from "smoothscroll-polyfill"

import getScrollYOffset from "./lib/getScrollYOffset"

import styles from "./styles.module.less"
import "./styles.less" // globally applied styles

import adobeLogo from "./components/Clients/img/adobeLogo.png"
import intelLogo from "./components/Clients/img/intelLogo.png"
import keiserLogo from "./components/Clients/img/keiserLogo.png"
import oregonLogo from "./components/Clients/img/oregonLogo.png"
import psLogo from "./components/Clients/img/psLogo.png"
import stumptownLogo from "./components/Clients/img/stumptownLogo.png"
import tacoLogo from "./components/Clients/img/tacoLogo.png"
import wildLogo from "./components/Clients/img/wildLogo.png"

import bgImage from "./img/background.jpg"
import right from "./img/right.svg"
import left from "./img/left.svg"
import up from "./img/top.svg"
import down from "./img/bottom.svg"

const clientLogos = [
  adobeLogo,
  intelLogo,
  keiserLogo,
  oregonLogo,
  psLogo,
  stumptownLogo,
  tacoLogo,
  wildLogo,
]

const config = {
  easing: "easeInOutSine",
  autoplay: false,
}

const timeline = [
  {
    targets: `.${styles.intro}`,
    duration: 250,
  },
  {
    targets: `.${styles.intro}`,
    translateX: "-100vw",
    duration: 350,
    delay: 0,
  },
  {
    targets: `.${styles.work}`,
    translateX: "0",
    duration: 200,
  },
  {
    targets: `.${styles.slides}`,
    translateX: "-300vw",
    duration: 2000,
  },
  {
    targets: `.${styles.work}`,
    translateX: "-100vw",
    duration: 800,
    delay: 0,
    offset: "-=800",
  },
  {
    targets: `.${styles.services}`,
    translateX: "0",
    duration: 250,
    offset: "-=400",
  },
  {
    targets: `[data-services-list]`,
    translateY: "-100%",
    duration: 1200,
  },
  {
    targets: `.${styles.services}`,
    translateY: "-250vw",
    duration: 600,
    delay: 0,
    offset: "-=200",
  },
  {
    targets: `.${styles.clients}`,
    translateY: "0vh",
    duration: 250,
    offset: "-=600",
  },
  {
    targets: `figure[data-client]`,
    translateY: (el, i, l) => `-${(l - i) * 33 + 33 * i + 100}vh`,
    duration: 750,
    offset: "-=400",
  },
  {
    targets: `.${styles.clients}`,
    translateY: "-100vh",
    duration: 350,
    offset: "-=350",
  },
  {
    targets: `.${styles.crew}`,
    translateY: "0vh",
    duration: 250,
    offset: "-=100",
  },
]

const navigation = {
  home: 0,
  work: 16,
  slides: 30,
  services: 55,
  clients: 82,
  people: 100,
}

// height in pixels = duration in miliseconds
const scrollHeight = timeline.reduce((total, current) => {
  let { delay = 0, duration = 0 } = current
  return total + delay + duration
}, 0)

const intro = {}

const offScreenX = {
  transform: "translateX(100vw)",
}

const offScreenY = {
  transform: "translateY(100vh)",
}

const work = {
  ...offScreenX,
}

const services = {
  ...offScreenX,
}

const crew = {
  ...offScreenY,
}

const clients = {
  ...offScreenY,
}

export default class Home extends Component {
  state = {
    progress: 0,
    height: scrollHeight,
    addClass: false,
    swiping: false,
    animation: null,
    debug: false,
  }

  componentDidMount() {
    // scroll, but smoothly
    smoothscroll.polyfill()
    // build animation from timeline
    this.buildAnimation()
    // handle internal linking
    if (window.location.search) {
      let search = window.location.search.replace("?", "")
      let nav = navigation[search]
      if (nav) {
        let handle = this.getClickHandler(nav)
        handle()
      }
      if (search === "debug") {
        this.setState({ debug: true })
      }
    }
    // handle scroll
    this.handleScroll = window.addEventListener("scroll", () => {
      let y = getScrollYOffset()
      // let pct = (y / scrollHeight) * 100
      const { animation } = this.state
      if (!animation) return
      animation.seek((y / scrollHeight) * animation.duration)
      this.setState({ progress: animation.progress })
    })

    this.handleWheel = window.addEventListener("wheel", e => {
      let { deltaX, deltaY } = e
      let x = Math.abs(deltaX)
      let y = Math.abs(deltaY)
      if (x > y) {
        e.preventDefault()
        window.scroll(window.scrollX, window.scrollY + e.deltaX)
      }
    })

    // handle blur of client logos
    if (IntersectionObserver) {
      this.iObserver = new IntersectionObserver(
        changes => {
          changes.forEach(change => {
            let isBlur = change.intersectionRatio >= 1 ? "false" : "true"
            change.target.setAttribute("data-blur", isBlur)
          })
        },
        {
          root: null,
          rootMargin: "-30px 0px -50px 0px",
          threshold: 1,
        }
      )
      document
        .querySelectorAll(`figure.${styles.client}`)
        .forEach(fig => this.iObserver.observe(fig))
    }
  }

  getClickHandler = pct => {
    return () => {
      let top = Math.ceil(scrollHeight * (pct / 100))
      window.scrollTo({
        top,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  componentWillUnmount() {
    if (this.handleScroll) {
      window.removeEventListener("scroll", this.handleScroll)
    }
    if (this.iObserver) {
      this.iObserver = null
    }
  }

  handleSwipeMove = (position, e) => {
    const { x, y } = position
    const scrollY = getScrollYOffset()
    if (Math.abs(y) < 10) {
      e.preventDefault()
      // do scrolly stuff
      let top = scrollY - 4 * x
      let left = 0
      let behavior = "smooth"
      window.scrollTo({
        top,
        left,
        behavior,
      })
    }
  }

  handleSwipeStart = () => {
    let swiping = true
    this.setState({ swiping })
  }

  handleSwipeEnd = () => {
    let swiping = false
    this.setState({ swiping })
  }

  buildAnimation = () => {
    const animation = anime.timeline(config)
    timeline.forEach(tick => {
      if (tick.offset) {
        animation.add(tick, tick.offset)
      } else {
        animation.add(tick)
      }
    })
    this.setState({ animation })
  }

  getProgress = () => {
    const { animation } = this.state
    return animation ? animation.progress : 0
  }

  render() {
    const { progress, debug, swiping } = this.state

    let show = {
      get homeBack() {
        return progress >= navigation.work && progress <= navigation.slides
      },
      get work() {
        return progress < navigation.work
      },
      // not really needed. Go from slides back home.
      get workBack() {
        return progress >= navigation.slides && progress < navigation.slides
      },

      get slides() {
        return progress >= navigation.work && progress < navigation.slides
      },
      get slidesBack() {
        return progress > navigation.slides && progress < navigation.clients
      },
      get services() {
        return progress >= navigation.slides && progress < navigation.services
      },
      get servicesBack() {
        return progress >= navigation.clients && progress < navigation.people
      },
      get clients() {
        return progress >= navigation.services && progress < navigation.clients
      },
      get clientsBack() {
        return progress >= navigation.people
      },
      get people() {
        return progress >= navigation.clients && progress < navigation.people
      },
    }

    const home = {
      height: `calc(100vh + ${scrollHeight}px)`,
    }

    const stage = {
      position: "fixed",
      top: "0",
      pointerEvents: swiping ? "none" : "auto",
    }

    return (
      <div className={styles.Home} style={home}>
        <div
          id="foobar"
          hidden={!debug}
          style={{
            color: "white",
            position: "fixed",
            top: "10px",
            left: "10px",
            zIndex: "999",
            fontSize: "16px",
          }}
        >
          {progress}
        </div>
        <BackButton
          title="back to home"
          onClick={this.getClickHandler(navigation.home)}
          show={show.homeBack}
        >
          <img src={left} alt="left" />
        </BackButton>

        <BackButton
          title="back to slides"
          onClick={this.getClickHandler(navigation.slides)}
          show={show.slidesBack}
        >
          <img src={left} alt="left" />
        </BackButton>
        <BackButton
          title="back to services"
          onClick={this.getClickHandler(navigation.services)}
          show={show.servicesBack}
        >
          <img src={up} alt="up" />
        </BackButton>
        <BackButton
          title="back to clients"
          onClick={this.getClickHandler(navigation.clients)}
          show={show.clientsBack}
        >
          <img src={up} alt="up" />
        </BackButton>
        <ForwardButton
          onClick={this.getClickHandler(navigation.work)}
          show={show.work}
        >
          <img src={right} alt="right" />
        </ForwardButton>
        <ForwardButton
          onClick={this.getClickHandler(navigation.services)}
          show={show.services}
        >
          <img src={right} alt="right" />
        </ForwardButton>
        <ForwardButton
          onClick={this.getClickHandler(navigation.slides)}
          show={show.slides}
        >
          <img src={right} alt="right" />
        </ForwardButton>
        <ForwardButton
          onClick={this.getClickHandler(navigation.clients)}
          show={show.clients}
        >
          <img src={down} alt="down" />
        </ForwardButton>
        <ForwardButton
          onClick={this.getClickHandler(navigation.people)}
          show={show.people}
        >
          <img src={down} alt="down" />
        </ForwardButton>

        <img className={styles.bgImage} alt="" src={bgImage} />
        <Swipe
          onSwipeMove={this.handleSwipeMove}
          onSwipeStart={this.handleSwipeStart}
          onSwipeEnd={this.handleSwipeEnd}
        >
          <div className="stage" style={stage}>
            <div className={styles.intro} style={intro}>
              <Reveal>
                <Intro />
              </Reveal>
            </div>
            <div className={styles.work} style={work}>
              <Work />
            </div>
            <div className={styles.slides} style={offScreenX}>
              <Slides />
            </div>
            <div className={styles.services} style={services}>
              <Services />
            </div>
            <div className={styles.clients} style={clients}>
              <Clients />
            </div>
            {clientLogos.map((logo, i) => {
              let h = i * 33
              let top = `${h}vh`
              let transform = `translateY(${h + 100}vh)`
              let style = {
                transform,
                top,
              }
              return (
                <figure
                  key={logo}
                  data-client={i}
                  data-blur="false"
                  className={styles.client}
                  style={style}
                >
                  <img className={styles.logo} src={logo} alt="" width="100" />
                </figure>
              )
            })}
            <div className={styles.crew} style={crew}>
              <Crew />
            </div>
          </div>
        </Swipe>
      </div>
    )
  }
}

const BackButton = ({ title, onClick, children, show = false }) => {
  if (!show) return null
  let styles = {
    position: "fixed",
    bottom: "15px",
    left: "15px",
    zIndex: "9999999",
    backgroundColor: "transparent",
    border: "none",
  }
  return (
    <div className={styles.buttonContainer}>
      <button
        title={title}
        style={styles}
        onClick={onClick}
        className={styles.buttonRight}
      >
        {children}
      </button>
    </div>
  )
}

const ForwardButton = ({ onClick, children, show = false }) => {
  if (!show) return null
  let styles = {
    position: "fixed",
    bottom: "15px",
    right: "15px",
    zIndex: "9999999",
    backgroundColor: "transparent",
    border: "none",
  }
  return (
    <div className={styles.buttonContainer}>
      <button style={styles} onClick={onClick} className={styles.buttonRight}>
        {children}
      </button>
    </div>
  )
}
