import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import HomeAnime from "../components/HomeAnime"

const HomePage = () => (
  <Layout bannerBG={false} navColor="white" bannerBgColor="transparent">
    <SEO
      title="Thesis | A digital agency"
      keywords={["thesis agency", "portland agency", "marketing agency"]}
      description="Thesis is a digital agency in Portland, OR. We’re hell-bent on humanizing people’s relationships with brands—that’s why we put the people first."
    />
    <HomeAnime />
  </Layout>
)

export default HomePage
