import React from "react"

import styles from "./styles.module.less"

const Intro = () => {
  return (
    <section className={styles.Intro}>
      <h1>
        Inspectors. <br className={styles.hide} />
        Connectors. <br />
        Dream-up-
        <br className={styles.hide} />
        what&apos;s-
        <br className={styles.hide} />
        next-ers.
        <br className={styles.hide} />
      </h1>
      <p>
        Thesis is made up of minds of all kinds. <br className={styles.hide} />
        But no robot brains here*— <br />
        just real people making real work.
      </p>
      <p>*Yet.</p>
    </section>
  )
}

export default Intro
