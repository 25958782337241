import React, { Component } from "react"
import { Link } from "gatsby"

import styles from "./styles.module.less"

import taco from "./img/taco-bell@3x.jpg"
import tacoSketch from "./img/tacobell-sketch@3x.png"

import eli from "./img/emerging-leaders@3x.jpg"

import eliSketch from "./img/emergingleaderssketch.png"
import eliSketch2x from "./img/emergingleaderssketch@2x.png"
import eliSketch3x from "./img/emergingleaderssketch@3x.png"

import tacoDesk from "./img/taco-desk.png"
import nikeDesk from "./img/nike-desk.png"
import eliDesk from "./img/eli-desk-compressed.png"
import beDesk from "./img/be-desk.png"
import beMob from "./img/be-mob.jpg"
import beSketch from "./img/be-sketch.png"

export default class Work extends Component {
  render() {
    return (
      <section className={styles.Work} id="work">
        <div className={styles.sticky}>
          <header>
            <h1>
              Our service
              <br className={styles.hide} /> is solving*.
            </h1>
            <p>
              <em>*And making. Lots of making.</em>
            </p>
          </header>
        </div>
      </section>
    )
  }
}

export const Slides = () => {
  return (
    <div className={styles.Slides}>
      <div className={styles.stage}>
        <Slide1 />
        <Slide2 />
        <Slide3 />
      </div>
      <div className={styles.stageDesk}>
        <Slide1Desk />
        <Slide3Desk />
        <Slide4Desk />
      </div>
    </div>
  )
}

export const Slide1 = () => {
  return (
    <figure className={styles.slide}>
      <Link to="/case-studies/tacobell">
        <img className={styles.main} src={taco} />
        <img src={tacoSketch} className={styles.sketch} />
        <figcaption>
          TACO BELL: Personalizing ecommerce for everybody’s favorite taco shop.
          <br />
        </figcaption>
      </Link>
    </figure>
  )
}

export const Slide2 = () => {
  return (
    <figure className={styles.slide}>
      <Link to="/case-studies/be">
        <img className={styles.main} src={beMob} />
        <img
          src={beSketch}
          srcSet={`${beSketch} 2x,
                ${beSketch} 3x`}
          className={styles.sketch}
        />
        <figcaption>
          BAY EQUITY: Building a new home for a people-first mortgage company.
        </figcaption>
      </Link>
    </figure>
  )
}

export const Slide3 = () => {
  return (
    <figure className={styles.slide}>
      <Link to="/case-studies/eli">
        <img className={styles.main} src={eli} />
        <img
          src={eliSketch}
          srcSet={`${eliSketch2x} 2x,
                  ${eliSketch3x} 3x`}
          className={styles.sketchHorz}
        />
        <figcaption>
          EMERGING LEADERS: Creating a new identity for our partners in equity.
        </figcaption>
      </Link>
    </figure>
  )
}

export const Slide1Desk = () => {
  return (
    <div className={styles.slideBlock}>
      <Link to="/case-studies/tacobell">
        <figure className={styles.slide}>
          <img className={styles.main} src={tacoDesk} />
          <figcaption>
            TACO BELL: Personalizing ecommerce for everybody’s favorite taco
            shop.
          </figcaption>
        </figure>
      </Link>
    </div>
  )
}

export const Slide2Desk = () => {
  return (
    <div className={styles.slideBlock}>
      <Link to="/case-studies/nike">
        <figure className={styles.slide}>
          <img className={styles.main} src={nikeDesk} />
          <figcaption>
            Nike: Strategic Approach to an Engaging Email Program for North
            America
          </figcaption>
        </figure>
      </Link>
    </div>
  )
}

export const Slide3Desk = () => {
  return (
    <div className={styles.slideBlock}>
      <Link to="/case-studies/eli">
        <figure className={styles.slide}>
          <img className={styles.main} src={eliDesk} />
          <figcaption>
            EMERGING LEADERS: Creating a new identity for our partners in
            equity.
          </figcaption>
        </figure>
      </Link>
    </div>
  )
}

export const Slide4Desk = () => {
  return (
    <div className={styles.slideBlock}>
      <Link to="/case-studies/be">
        <figure className={styles.slide}>
          <img className={styles.main} src={beDesk} />
          <figcaption>
            BAY EQUITY: Building a new home for a people-first mortgage company.
          </figcaption>
        </figure>
      </Link>
    </div>
  )
}
