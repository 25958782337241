import React, { Component } from "react"
import { Link } from "gatsby"
import styles from "./styles.module.less"
import emdash from "./../../img/emdash.png"

export default class Services extends Component {
  render() {
    return (
      <section className={styles.Services}>
        <div className={styles.sticky}>
          <div className={styles.flex}>
            <header>
              <h1>
                How?
                <br className={styles.hide} /> Depends what* <br /> keeps you up
                at night.
              </h1>
            </header>
            <div className={styles.blank}>
              <div className={styles.listBox}>
                <ul data-services-list className={styles.list}>
                  <li>HTML email</li>
                  <li>Digital marketing</li>
                  <li>Digital brand strategy &amp; design</li>
                  <li>Web design</li>
                  <li>Mobile applications</li>
                  <li>Product engineering &amp; development</li>
                  <li>Integrated tech solutions</li>
                  <li>Copywriting &amp; editing</li>
                  <li>SEO</li>
                  <li>Media strategy</li>
                  <li>Paid media</li>
                  <li>Social media</li>
                  <li>UX/UI</li>
                  <li>Motion graphics</li>
                  <li>Client services</li>
                  <li>Creative operations</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.absolute}>
            <nav className={styles.links}>
              <Link to="/about#services" className={styles.cta}>
                <span>See the services</span>
                <img alt="" src={emdash} />
              </Link>
            </nav>
          </div>
        </div>
      </section>
    )
  }
}

//How? Depends what* keeps you up at night.
