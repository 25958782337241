import React, { Component } from "react"
import { Link } from "gatsby"
import styles from "./styles.module.less"
import emdash from "./../../img/emdash.png"

export default class Crew extends Component {
  render() {
    return (
      <section className={styles.Crew}>
        <div>
          <p>Yes, well-considered work is our thing.</p>
          <h1>
            All hail the <br className={styles.hide} />
            heads, hearts, <br />
            and hands
            <br className={styles.hide} /> who make it.
          </h1>
        </div>
        <div className={styles.absolute}>
          <nav className={styles.links}>
            <Link to="/people" className={styles.cta}>
              <span>Meet the people</span>
              <img alt="" src={emdash} />
            </Link>
          </nav>
        </div>
      </section>
    )
  }
}
